html,
div,
span,
h1,
h2,
h3,
p,
a,
img,
i,
ul,
li,
form,
label,
article,
canvas,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}
html {
  line-height: 1;
}

ul {
  list-style: none;
}

a img {
  border: none;
}

article,
footer,
header,
main,
nav,
section {
  display: block;
}

button {
  background: none;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
}
input {
  background: none;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
}
img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: SFProText, Regular, sans-serif;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'SFProText-Regular';
  src: url(./fonts/SFProText-Regular.eot);
  src: url(./fonts/SFProText-Regular.eot?#iefi) format('embedded-opentype'),
    url(./fonts/SFProText-Regular.svg#SFProText-Regular) format('svg'),
    url(./fonts/SFProText-Regular.ttf) format('truetype'),
    url(./fonts/SFProText-Regular.woff) format('woff'),
    url(./fonts/SFProText-Regular.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

.sc-iCfMLu .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent;
  color: #fff;
  border: 1px solid gray;
}

.sc-iCfMLu .ant-select-arrow {
  color: gray;
}

.ant-page-header-heading {
   flex-wrap: wrap;
 }